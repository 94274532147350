<template>
  <div>
    <v-layout pb-6 pr-6 pl-6 class="mainfont" wrap justify-center>
      <v-flex xs12 pt-4>
        <v-layout wrap justify-center>
          <v-snackbar v-model="showSnackBar" color="black">
            <v-layout wrap justify-center>
              <v-flex text-left class="align-self-center">
                <span style="color: white">
                  {{ msg }}
                </span>
              </v-flex>
              <v-flex text-right>
                <v-btn small :ripple="false" text @click="showSnackBar = false">
                  <v-icon style="color: white">mdi-close</v-icon>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-snackbar>
          <vue-element-loading
            :active="appLoading"
            :is-full-screen="true"
            background-color="#FFFFFF"
            color="#42275a"
            spinner="spinner"
          />
        </v-layout>
        <v-layout wrap justify-start>
          <v-flex xs11 lg8 pt-8 text-left>
            <span style="font-size: 25px; font-weight: bolder">
              APPLICATION PROGRESS
            </span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 pt-4>
        <v-card>
          <v-layout wrap>
            <v-flex xs12 pt-4>
              <v-layout wrap justify-center>
                <v-flex xs2 text-center><span>Forward Date</span></v-flex>
                <v-flex xs3 text-center><span>Forward From</span></v-flex>
                <v-flex xs3 text-center><span>Forward To</span></v-flex>
                <v-flex xs2 text-center><span>Process Done</span></v-flex>
                <v-flex xs2 text-center><span>Remarks</span></v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 pt-1 pb-1> <v-divider></v-divider></v-flex>
            <v-flex xs12 pt-4 v-for="(item, i) in list" :key="i">
              <v-layout wrap justify-center style="font-size: 14px" pa-1>
                <v-flex xs2 text-center>
                  <span v-if="item.forwardDate">
                    {{ formatDate(item.forwardDate) }} <br />
                    {{ formatForwardDate(item.forwardDate) }}
                  </span>
                  <span v-else> Nill </span>
                </v-flex>
                <v-flex xs3 text-center v-if="item">
                  <span v-if="item.forwardFrom">
                    <span v-if="item.forwardFrom.officeName">
                      {{ item.forwardFrom.username }}
                    </span>
                    <span v-if="item.forwardFrom.uType == '774'"> ( DFO) </span>
                    <span v-if="item.forwardFrom.uType == '7741'">
                      ( Section Clerk)
                    </span>
                    <span v-if="item.forwardFrom.uType == '7742'">
                      ( Superintedent)
                    </span>
                    <span v-if="item.forwardFrom.uType == '777'">
                      ( Super Admin)
                    </span>
                    <span v-if="item.forwardFrom.uType == '772'">
                      ( User)
                    </span>
                  </span>
                  <span v-else> Nill </span>
                </v-flex>
                <v-flex xs3 text-center v-if="item">
                  <span v-if="item.forwardTo">
                    <span v-if="item.forwardTo.officeName">
                      {{ item.forwardTo.username }}
                    </span>
                    <span v-if="item.forwardTo.uType == '777'"> (Super Admin) 
                    </span>
                    <span v-if="item.forwardTo.uType == '774'"> ( DFO) </span>
                    <span v-if="item.forwardTo.uType == '7741'">
                      ( Section Clerk)
                    </span>
                    <span v-if="item.forwardTo.uType == '7742'">
                      ( Superintedent)
                    </span>
                    <span v-if="item.forwardTo.uType == '772'"> ( User) </span>
                  </span>
                  <span v-else> Nill </span>
                </v-flex>
                <v-flex xs2 text-center v-if="item">
                  <span v-if="item">
                    {{ item.processDone }}
                  </span>
                  <span v-else> Nill </span>
                </v-flex>
                <v-flex xs2 text-center v-if="item">
                  <span v-if="item.remarks">
                    {{ item.remarks }}
                  </span>
                  <span v-else> Nill </span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pl-6>
        <v-flex xs3 pb-3>
    <v-btn color="primary" @click="goBack">
  <span style="text-transform: none; font-size: 15px; color: white;">
    GO BACK
  </span>
</v-btn>
  </v-flex>
      </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      itemArray2: ["Vegeterian", "Non-vegetarian"],
      // cdate: moment().format("YYYY-MM-DD"),
      curIdToDelete: null,
      name: null,
      editdialog: false,
      barlist: [],
      edit: [],
      userProfileTabs: null,
      subtab: null,
      appLoading: false,
      page: 1,
      keyArray: [],
      dialogVisible: false,
      file: null,
      currentItemId: null,
      pages: 0,
      dialog1: false,
      preview: null,
      tab: null,
      barlist1: [],
      dialog3: false,
      deletedialog: false,
      minAmount: null,
      password: null,
      categoryarray: [],
      list: [],
      id: this.$route.query._id,

      flag: false,
      flag2: false,
      auctionbased: [],
      currentpage: 1,
      menu1: false,
      cdate: null,
      menu2: false,
      menu3: false,
      menu4: false,
      fromDate: new Date().toISOString().substr(0, 10),
      toDate: null,
      fromDate2: null,
      toDate2: null,
      msg: null,
      currentPage: 1,
      showSnackBar: false,
      info: [],
      dialog2: false,
      viewdialog: false,
    };
  },

  mounted() {
    this.getList();
  },
  watch: {
    currentPage() {
      this.getList();
    },
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
  },
  computed: {
    guestPhoneRules() {
      return [
        (v) => !!v || "Phone number is required",
        (v) => /^[0-9]{10}$/.test(v) || "Phone number must be 10 digits",
      ];
    },
    emailRules() {
      return [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ];
    },
    minDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    formattedDate() {
      const date = new Date(this.edit.selectedDate);
      return date.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      });
    },
  },

  methods: {
    goBack() {
  
  this.$router.go(-1);
},
    formatDate(dateString) {
      // Assuming dateString is in a format that can be parsed by Date constructor
      const date = new Date(dateString);

      // Format the date only
      const options = { year: "numeric", month: "long", day: "numeric" };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );

      return formattedDate;
    },

    formatForwardDate(dateString) {
      // Assuming dateString is in a format that can be parsed by Date constructor
      const date = new Date(dateString);

      // Format the date and time
      const options = { hour: "numeric", minute: "numeric", hour12: true };
      const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
        date
      );

      return formattedDate;
    },
    getList() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/records/application",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          appid: this.$route.query.id,
        },
      })
        .then((response) => {
          this.list = response.data.data.map((item) => {
            const date = new Date(item.selectedDate);
            item.selectedDate = date.toLocaleDateString();
            item.cdate = moment(item.selectedDate).format("YYYY-MM-DD");
            return item;
          });
          this.totalData = response.data.totalLength;
          this.selectedDatesArray = this.list.map((item) => item.selectedDate);
          this.pages = Math.ceil(this.totalData / response.data.limit);
          this.appLoading = false;
        })
        .catch((err) => {
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>